import { routes as auth } from '../modules/auth'
import { routes as home } from '../pages/home'
import { routes as users } from '../pages/users'
import { routes as events } from '../pages/events'
import { routes as invoices } from '../pages/invoices'

export default [
  ...auth,
  ...home,
  ...users,
  ...events,
  ...invoices
]
