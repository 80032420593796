import { store as auth } from '@/modules/auth'
import { store as events } from '@/pages/events'
import { store as invoices } from '@/pages/invoices'
import { store as users } from '@/pages/users'

export default {
  auth,
  events,
  invoices,
  users
}
