import http from '@/http'
const URL = ''

export const login = (payload) => http.post(URL + '/auth/login', payload)
export const logout = (payload) => http.post(URL + '/auth/logout', payload)
export const loadSession = () => http.get(URL + '/user/me')

export default {
  login,
  logout,
  loadSession
}
