export default [
  {
    name: 'invoices',
    path: '/invoices',
    component: () => import('./Index'),
    meta: {
      showNavbar: true,
      title: 'Minhas Faturas',
      icon: 'dvr',
      label: 'Minhas Faturas'
    }
  },
  {
    name: 'invoice-show',
    path: '/invoices/show/:id',
    component: () => import('./Show'),
    meta: {
      showNavbar: false,
      title: 'Minhas Faturas - Detalhes',
      icon: 'dvr',
      label: 'Minhas Faturas - Detalhes'
    }
  }
]
