import http from '@/http'
const URL = '/events'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=users,dns`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}

export const checkDomainDNS = (payload) => {
  return http.post('/utils/checkDNS', payload)
}

export const listPlans = async (params) => {
  const plans = await http.get('/utils/plans', { params: params })
  return plans
}

export const domain = async (params) => {
  const domain = await http.get('/utils/domain', { params: params })
  return domain
}

export default {
  save,
  list,
  checkDomainDNS,
  listPlans,
  show,
  domain
}
