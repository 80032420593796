export default [
  {
    name: 'events',
    path: '/events',
    component: () => import('./Index'),
    meta: {
      showNavbar: true,
      title: 'Meus Eventos',
      icon: 'dvr',
      label: 'Meus Eventos'
    }
  },
  {
    name: 'event-edit',
    path: '/events/edit/:id',
    component: () => import('./Edit'),
    meta: {
      showNavbar: false,
      title: 'Meus Eventos - Edição',
      icon: 'dvr',
      label: 'Meus Eventos - Edição'
    }
  },
  {
    name: 'event-in-production',
    path: '/events/production/:id',
    component: () => import('./WaitEvent'),
    meta: {
      showNavbar: false,
      title: 'Meus Eventos - Em Produção',
      icon: 'dvr',
      label: 'Meus Eventos - Em Produção'
    }
  },
  {
    name: 'event-new',
    path: '/events/new',
    component: () => import('./New'),
    meta: {
      showNavbar: false,
      title: 'Meus Eventos - Novo evento',
      icon: 'dvr',
      label: 'Meus Eventos - Novo evento'
    }
  }
]
